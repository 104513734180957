import * as React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import HeroSection from "../components/OurFootprint/HeroSection";
import Networks from "../components/OurFootprint/Footprints";
import Seo from "../components/seo";
import { MobileView } from 'react-device-detect';

const OurNetworks = () => (
  <Layout>
    <Seo title="Our Networks" />
    <MobileView>
      <HeroSection />
    </MobileView>
    <Networks />
  </Layout>
);

export default OurNetworks;
